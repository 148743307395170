import Vue from 'vue'
import Vuex from 'vuex'

import dominios from './dominios';
import auth from './auth';
import alerts from './alerts';
import contents from './contents';
import alumni from './alumni';

Vue.use(Vuex)


let server = (location.hostname == 'padres-ufv.es') ? 'https://crm.alumni-ufv.es/' : 'https://dmb-alumni.dev.doubledot.es/'



export default new Vuex.Store({
  state: {
    language: 'es',
    server: server,
    showmenuperfil: false
  },
  getters: {
    getShowmenuperfil: state => state.showmenuperfil,
  },
  mutations: {
    toggleMenuPerfil(state) {
      state.showmenuperfil = !state.showmenuperfil
    }
  },
  actions: {
    toggleMenuPerfil({ commit }) {
      commit('toggleMenuPerfil');
    },
    initapp({ dispatch, getters }) {
      if (getters.getLogged) {
        dispatch('getAlumni')
      }
    }
  },
  modules: {
    dominios,
    auth,
    alerts,
    contents,
    alumni
  }
})
